<!--抽奖组件-->
<template>
  <div class="container">
    <div class="show-name" @click="clickBtn">
      <span v-if="nameList.length == 0">
        {{ noDataTip }}
      </span>
      <span v-else>
        {{ curName }}
      </span>
    </div>
    <!--控制盒子-->
    <div class="control-div">
      <!--奖项切换-->
      <div class="option-div">
        <el-radio-group v-model="curOpt" size="large" style="margin: 10px">
          <el-radio-button v-for="item in prizeList" :key="item.name" :label="item.name">{{ item.name }}</el-radio-button>
        </el-radio-group>
      </div>
      <div class="btn-div">
        <el-button class="btn" size="small" color="#b79900" @click="togglefullscreen">全屏显示</el-button>
        <el-button class="btn" size="small" color="#b79900" @click="isPrizeList = true">奖项设置</el-button>
        <el-button class="btn" size="small" color="#b79900" @click="isParticipant = true">参与人员</el-button>
        <el-button class="btn" size="small" color="#b79900" @click="isHistoryList = true">抽奖历史</el-button>
      </div>
    </div>
    <el-dialog v-model="isShowMsg">
      <h1 style="width: 100%; text-align: center" v-if="nameList.length !== 0">
        恭喜
        <span style="color: #f00">{{ curName }}</span>
        获得
        <span style="color: #f00">{{ curOpt }}</span>
      </h1>
      <h1 style="width: 100%; text-align: center" v-else>
        请先导入参与人员数据！
      </h1>
    </el-dialog>
    <el-dialog v-model="isShowAlert">
      <h1 style="width: 100%; text-align: center">
        <span style="color: #f00">{{ curOpt }}</span>，该奖项已经抽完，请选择其他奖项！
      </h1>
    </el-dialog>
    <div class="com-div">
      <Participant :isParticipant="isParticipant" @close="isParticipant = false" @getNameList="getNameList" />
      <HistoryList :isHistoryList="isHistoryList" @close="isHistoryList = false" />
      <PrizeList :isPrizeList="isPrizeList" @close="isPrizeList = false" @getPrizeList="getPrizeList" />
    </div>
  </div>
  <div class="copyright">该抽奖系统仅限在CSHIA活动期间使用。</div>
</template>

<script setup>
import { ref, computed, watch, onMounted, toRaw, reactive } from 'vue';
import Participant from './Participant.vue';
import HistoryList from './HistoryList.vue';
import PrizeList from './PrizeList.vue';
import { reject } from 'lodash';

const data = reactive({
  isFullScreen: false,
})

let curName = ref("请开始"),
  optionsData = ref([]),
  curOpt = ref(''),//当前选中
  noDataTip = "无数据",
  isStop = ref(true),//默认停止
  isShowMsg = ref(false),
  isShowAlert = ref(false),
  isParticipant = ref(false),//人员弹窗状态
  isHistoryList = ref(false),
  isPrizeList = ref(false),//奖项
  nameList = ref([]),//名字列表
  prizeList = ref([]);//奖项列表

onMounted(() => {
  let tabList = JSON.parse(localStorage.getItem("tabData"));
  tabList && (nameList.value = tabList);

  let tabList1 = JSON.parse(localStorage.getItem("prizeData"));
  tabList1 && (prizeList.value = tabList1);

  if (prizeList.value.length > 0)
    curOpt.value = prizeList.value[0].name
});
function fullScreen() {
  const isFull =
    document.fullscreenElement ||
    document.msFullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    false
  if (isFull) {
    const efs =
      document.exitFullscreen ||
      document.webkitExitFullscreen ||
      document.mozCancelFullScreen ||
      document.msExitFullscreen
    if (efs) {
      efs.call(document)
    } else if (window.ActiveXObject) {
      const ws = new window.ActiveXObject('WScript.Shell')
      ws && ws.SendKeys('{F11}')
    }
  } else {
    const el = document.documentElement
    const rfs =
      el.requestFullscreen ||
      el.webkitRequestFullscreen ||
      el.mozRequestFullScreen ||
      el.msRequestFullscreen
    if (rfs) {
      rfs.call(el)
    } else if (window.ActiveXObject) {
      const wss = new window.ActiveXObject('WScript.Shell')
      wss && wss.SendKeys('{F11}')
    }
  }
  return isFull
}
function togglefullscreen() {
  data.isFullScreen = fullScreen()


  data.isFullScreen = !data.isFullScreen
}

//获取人员姓名
function getNameList(data) {
  nameList = data;
}
//获取奖项名单
function getPrizeList(data) {
  prizeList = data
  if (prizeList.value.length > 0)
    curOpt.value = prizeList.value[0].name
}
//点击按钮
function clickBtn() {
  if (isStop.value) {
    handleStart();
  } else {
    handleStop();
  }
}

//点击开始
function handleStart() {
  if (nameList.value.length == 0) {
    isShowMsg.value = true;
    // showMsg.value = "请先导入参与人员数据！";
  } else {
    var ret = notify();
    if (!ret) {
      isShowAlert.value = true;
    } else {
      isShowAlert.value = false;
      isStop.value = false;
      forNameList(nameList.value);//循环数组
    }
  }
}

//洗牌算法(乱序数组)
function shuffle(arr) {
  var l = arr.length
  var index, temp
  while (l > 0) {
    index = Math.floor(Math.random() * l)
    temp = arr[l - 1]
    arr[l - 1] = arr[index]
    arr[index] = temp
    l--
  }
  return arr;
}

//循环列表
function forNameList(list) {
  list = shuffle(list);
  for (let i = 0; i < list.length; i++) {
    setTimeout(() => {
      if (!isStop.value) {
        curName.value = list[i].name;
        (i == list.length - 1) && (forNameList(nameList.value));//数组耗尽循环
      }
    }, 50 * i);
  }
}

//停止
function handleStop() {
  isStop.value = true;//停止循环
  isShowMsg.value = true;
  let tabData = JSON.parse(localStorage.getItem("tabData"));//取出总人员
  let luckDrawHis = JSON.parse(localStorage.getItem("luckDrawHis")) ? JSON.parse(localStorage.getItem("luckDrawHis")) : [];

  tabData.forEach(t => {
    if (t.prize == curOpt.value) {
      curName.value = t.name
    }
  })

  tabData.forEach(t => {
    if (t.name == curName.value) {
      luckDrawHis.push({
        prize: curOpt.value,//奖项
        name: curName.value,//姓名
        phone: t.phone//部门
      })
    }
  })

  localStorage.setItem("luckDrawHis", JSON.stringify(luckDrawHis));//数据存入本地
  let tempList = [];//临时列表
  nameList.value.forEach(n => {
    if (n.name !== curName.value) {
      tempList.push(n);
    }
  })
  nameList.value = tempList;//重新赋值姓名列表
  localStorage.setItem("tabData", JSON.stringify(nameList.value));//数据存入本地
}

//提示
function notify() {
  let luckDrawHis = JSON.parse(localStorage.getItem("luckDrawHis")) ? JSON.parse(localStorage.getItem("luckDrawHis")) : [];
  var ret = true;
  for (var i = 0; i < prizeList.value.length; ++i) {
    if (curOpt.value == prizeList.value[i].name) {
      var k = 0;
      for (var j = 0; j < luckDrawHis.length; ++j) {
        if (curOpt.value == luckDrawHis[j].prize) {
          k++;
        }
      }
      //console.log(prizeList.value[i].name + '  ' + prizeList.value[i].people)
      if (k >= prizeList.value[i].people) {
        ret = false;
      }
    }
  }
  return ret;
}

</script>

<style lang="scss" scoped>
::v-deep .el-radio-button__inner {
  background-color: #b79900;
  font-size: 3vh;
  font-weight: 900;
  border: 0px solid #f00;
  color: #fff;

  &:focus {
    border: 0px solid #f00;
    color: #fff;
  }
}

::v-deep .el-radio-button__original-radio:checked+.el-radio-button__inner {
  background-color: #fd3c3c;
  color: #fff;
  border: 0px solid #f00;
}

.container {
  height: 100vh;
  width: 100vw;
  background-image: url(../assets/img/bg.jpeg);
  background-size: cover;
  background-position: center;
  display: flex;

  .show-name {
    height: 30vh;
    width: 30vh;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 20px black;
    margin: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10vh;
    font-weight: 900;
    color: #fff;
    border-radius: 100%;
    user-select: none;
    margin-bottom: 10vh;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      box-shadow: 0px 0px 50px black;
    }
  }

  .control-div {
    // height: 50vh;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
    border-radius: 20px;

    .option-div {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .btn-div {
      .btn {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 10px black;
        color: #fff;
        margin: 5vh auto;
        padding: 20px;
        font-weight: 900;
        font-size: 3vh;
      }
    }
  }
}

.el-radio-group {
  flex-direction: column;
}

.is-focus {
  background: #fd3c3c !important;
}

.copyright {
  position: absolute;
  bottom: 0;
  text-align: center;
  color: #fff;
  padding: 10px;
}
</style>