<template>
  <LuckDraw />
</template>

<script>
import LuckDraw from './components/LuckDraw.vue'

export default {
  name: 'App',
  components: {
    LuckDraw
  }
}
</script>

<style lang="scss">
#app {}
</style>
